import { faClose } from "@fortawesome/free-solid-svg-icons";
import styles from "../../Styles/products.module.css";
import InputField from "../InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleSwitch from "../ToggleSwitch";
import { useState } from "react";

const Specifications = (props: any) => {
  const [temp, setTemp] = useState("");

  return (
    <>
      <div className={`${styles.field_container}`}>
        <div className={styles.variant_heading}>
          <h3>Specifications</h3>
          <button onClick={props.handleAddSpec}>Add</button>
        </div>
        <div className={styles.variants_container}>
          {[...props.state.specifications].map((val: any, index: any) => {
            return (
              <div className={styles.variant_container} key={index}>
                <InputField
                  type="text"
                  value={val.options_name}
                  onChange={(e: React.BaseSyntheticEvent) => {
                    props.handleSpecProperties(e, index);
                  }}
                  isLabel={true}
                  lable="Option name"
                  name="options_name"
                />
                <InputField
                  type="string"
                  value={temp}
                  onChange={(e: any) => {
                    setTemp(e.target.value.toLowerCase());
                    // props.handleSpecProperties(e, index);
                  }}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      props.handleSpecProperties(
                        {
                          target: {
                            name: "options_value",
                            value: [...val.options_value, temp],
                          },
                        },
                        index
                      );
                      setTemp("");
                    }
                  }}
                  isLabel={true}
                  lable="Option value (Press Enter to add Value)"
                  name="options_value"
                />
                <div
                  style={{
                    paddingInline: "10px",
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  {val.options_value.map((v: any, i: any) => {
                    return (
                      <p
                        key={i}
                        style={{
                          background: "var(--blue-light)",
                          color: "var(--blue-dark)",
                          padding: "5px 10px",
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "7px",
                          borderRadius: "5px",
                        }}
                      >
                        {v}
                        <FontAwesomeIcon
                          icon={faClose}
                          cursor={"pointer"}
                          onClick={() => {
                            let t = [...val.options_value];
                            t.splice(i, 1);
                            props.handleSpecProperties(
                              {
                                target: { name: "options_value", value: t },
                              },
                              index
                            );
                          }}
                        />
                      </p>
                    );
                  })}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <label>Is Visible</label>
                  <ToggleSwitch
                    value={val.isVisible}
                    onClick={() => {
                      props.handleSpecProperties(
                        {
                          target: { name: "isVisible", value: !val.isVisible },
                        },
                        index
                      );
                    }}
                  />
                </div>

                <div
                  className={styles.remove_variant}
                  onClick={() => props.handleRemoveSpec(index)}
                >
                  <FontAwesomeIcon icon={faClose} color="white" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Specifications;
