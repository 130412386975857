import React, { useEffect, useRef, useState } from "react";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import ContentBody from "../../../Components/Content/ContentBody";
import styles from "../../../Styles/orderDetails.module.css";
import InputField from "../../../Components/InputField";
import withRouter from "../../../Components/withRouter";
import axios from "axios";
import { toast } from "react-hot-toast";

import { useReactToPrint } from "react-to-print";
import { useLocation, useParams } from "react-router-dom";
import ShippingLabel from "../../../Components/shippingLabelGenerator/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faCross,
  faFileExport,
  faFilePdf,
  faPlus,
  faReceipt,
  faSearch,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import Invoice from "../../../Components/Invoice/invoice";
import PrintOptions from "../../../Components/Invoice/prePrintModal/prePrintModal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ThermalInvoice from "../../../Components/Invoice/ThermalInvoice/thermalInvoice";
import SecondaryView from "./secondaryView";
import SingleRow from "./singleRow";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../Assets/icons/edit.svg";
import { CSVLink } from "react-csv";
import Pagination from "../../../Components/Table/Pagination";
import { ReactComponent as CloseIcon } from "../../../Assets/icons/cross.svg";
import TableHead from "../../../Components/Table/TableHead";

type Props = {
  key: string;
  data: any;
  loading: boolean;
  fulfilmentStatus: any;
  paymentStatus: any;
  returnStatus: string;
  refundStatus: string;
  cancelModal: boolean;
  statusUpdateModal: boolean;
  returnStatusModal: boolean;
  refundStatusModal: boolean;
  labelGeneratorModal: boolean;
  printOptionModal: boolean;
  cancelReturnStatus: string;
  cancelRefundStatus: string;
  admin: any;
  user: any;
  product: any;
  componentRef: any;
};

type statusUpdate = {
  fulfilmentStatus?: any;
  refundStatus?: string;
  returnStatus?: string;
  paymentStatus?: string;
};

const ProductOrderDetails = ({ props }: any) => {
  const [key, setKey] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fulfilmentStatus, setFulfilmentStatus] = useState("");
  const [refundStatus, setRefundStatus] = useState("");
  const [returnStatus, setReturnStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const [statusUpdateModal, setStatusUpdateModal] = useState(false);
  const [returnStatusModal, setReturnStatusModal] = useState(false);
  const [refundStatusModal, setRefundStatusModal] = useState(false);
  const [labelGeneratorModal, setLabelGeneratorModal] = useState(false);
  const [printOptionModal, setPrintOptionModal] = useState(false);
  const [cancelReturnStatus, setCancelReturnStatus] = useState("");
  const [cancelRefundStatus, setCancelRefundStatus] = useState("");
  const [admin, setAdmin] = useState({});
  const [user, setUser] = useState<any>();
  const [product, setProduct] = useState([]);
  const componentRef = useRef<HTMLDivElement>();
  const targetRef = useRef<HTMLDivElement | any>();
  const thermalInvoiceRef = useRef<HTMLDivElement | any>();
  const [searchTerm, setSearchTerm] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const navigate = useNavigate();
  const [secondaryView, setSecondaryView] = useState(false);
  const router = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [disableForAction, setDisableForAction] = useState(false);
  const [inView, setInView]: any = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [editOrderStatus, setEditOrderStatus] = useState(false);
  const [editPaymentStatus, setEditPaymentStatus] = useState(false);
  const [csvData, setCSVData] = useState<any>();
  const [tempPaymentStatus, setTempPaymentStatus] = useState("");
  const [tempOrderStatus, setTempOrderStatus] = useState("");
  const buttonRef = useRef<any>();
  const location = useLocation();
  const [filter, setFilter]: any = useState({
    search: "",
  });
  const params = useParams();

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (option: string) => {
    if (option == "pdf") {
      downloadPDF();
    } else {
      if (localStorage.getItem("printingOption")) {
        if (localStorage.getItem("printingOption") === "thermal") {
          handlePrintThermal();
        } else {
          handlePrintInvoice();
        }
      } else {
        setPrintOptionModal(true);
      }
    }
    setSelectedOption(option);

    setIsOpen(false);
  };
  const handleGenetrateLabel = () => {
    setLabelGeneratorModal(true);
  };

  useEffect(() => {
    // getOrderDetails();
    getKey();
    getAdminByToken();
    // getCustomerDetails()
  }, []);

  useEffect(() => {
    getData();
  }, [location.search]);

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}orders/getAllOrdersEcom/?identifier=` +
        localStorage.getItem("identifier") +
        "&orderStatus=true" +
        `&page=${Number(search.get("page")) || 1}` +
        "&limit=" +
        15 +
        `${location.search.slice(8)}` +
        `${search.get("search") ? `&search=${search.get("search")}` : ``}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setTotal(res.data.results);
        const transformedData = transformOrdersToCSVFormat(res.data.orders);

        setCSVData(transformedData);
        const temp_data = res.data.orders.map((val: any) => ({
          ...val,
          date:
            val?.date?.split("T")[0]?.split("-").reverse().join("/") || "--",
        }));
        setData(temp_data);
        if (Object.keys(inView).length !== 0) {
          for (let i = 0; i < temp_data.length; i++) {
            if (inView._id === temp_data[i]._id) {
              setInView(temp_data[i]);
              setEditOrderStatus(false);
              setEditPaymentStatus(false);
            }
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setData([]);

        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerDetails(inView?.customerId);
  }, [inView]);

  const getKey = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}users/verifyuser`,
      method: "POST",
      data: {
        identifier: localStorage.getItem("identifier"),
        consent: true,
      },
    })
      .then((res) => {
        setKey(res?.data?.key);

        if (res?.data?.key) {
          getUserDetailsFromRetaino(res?.data?.key);
        }
      })
      .catch((error) => {});
  };
  const calculatePriceWithTax = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ): number => {
    // this will return the actual price of item after removing the tax.
    if (isInclusive) {
      return Number((subTotal * (100 / (100 + gst))).toFixed(2));
    }
    // this will return the price after adding tax .
    return Number((subTotal + (gst / 100) * subTotal).toFixed(2));
  };

  const transformOrdersToCSVFormat = (orders: any) => {
    const data: any[] = [];
    orders.forEach((order: any) => {
      const {
        products,
        date,
        fulfilmentStatus,
        total,
        subTotal,
        tax,
        billingAddress,
        shippingAddress,
        orderType,
        orderRefId,
      } = order;

      products?.forEach((item: any, index: any) => {
        data.push({
          orderRefId: index > 0 ? "" : orderRefId || "",
          date:
            index > 0
              ? ""
              : date
              ? new Date(order.date).toLocaleDateString()
              : "", // Order Date
          orderStatus: index > 0 ? "" : fulfilmentStatus,
          customerId: index > 0 ? "" : order.customerId,

          billingName: index > 0 ? "" : billingAddress?.name || "",
          billingAddressLine1:
            index > 0 ? "" : billingAddress?.addLineOne || "",
          billingAddressLine2:
            index > 0 ? "" : billingAddress?.addLineTwo || "",
          billingCity: index > 0 ? "" : billingAddress?.city || "",
          billingState: index > 0 ? "" : billingAddress?.state || "",
          billingPincode: index > 0 ? "" : billingAddress?.pincode || "",
          shippingName: index > 0 ? "" : shippingAddress?.name || "",
          shippingAddressLine1:
            index > 0 ? "" : shippingAddress?.addLineOne || "", // Shipping Address Line 1
          shippingAddressLine2:
            index > 0 ? "" : shippingAddress?.addLineTwo || "", // Shipping Address Line 2
          shippingCity: index > 0 ? "" : shippingAddress?.city || "", // Shipping City
          shippingState: index > 0 ? "" : shippingAddress?.state || "", // Shipping State
          shippingPincode: index > 0 ? "" : shippingAddress?.pincode || "", // Shipping Pincode

          productName: item?.productId?.title,
          category: item?.productId?.category,
          quantity: item?.quantity,

          subTotal: item?.productId?.pricing?.inclusiveOfGST
            ? calculatePriceWithTax(
                item?.productId?.pricing?.price,
                true,
                item?.productId?.pricing?.igst
              )
            : item?.productId?.pricing?.price,

          tax: item?.productId?.pricing?.igst || 0,

          taxAmount: item?.productId?.pricing?.inclusiveOfGST
            ? (
                (item?.productId?.pricing?.price *
                  item?.productId?.pricing?.igst) /
                (100 + item?.productId?.pricing?.igst)
              ).toFixed(2)
            : (
                item?.productId?.pricing?.price *
                item?.productId?.pricing?.igst *
                0.01
              ).toFixed(2),

          total: item?.productId?.pricing?.inclusiveOfGST
            ? // calculatePriceWithTax(
              item?.productId?.pricing?.price * item?.quantity
            : //   true,
              //   item?.productId?.pricing?.igst
              // )
              calculatePriceWithTax(
                item?.productId?.pricing?.price * item?.quantity,
                false,
                item?.productId?.pricing?.igst
              ),
        });
      });
    });
    return data;
  };

  const downloadPDF = () => {
    const input = targetRef.current;

    html2canvas(input, { backgroundColor: "#fff" }).then((canvas: any) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      // @ts-ignore
      pdf.save(`${inView.orderRefId}.pdf`);
    });
  };
  const getAdminByToken = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}admins/getAdminByToken`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setAdmin(res.data.data);
      })
      .catch((err: any) => {});
  };

  const getUserDetailsFromRetaino = (key: string) => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}users/` + key,
      method: "GET",
      // headers: {
      //   Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      // },
    })
      .then((res) => {})
      .catch((error) => {});
  };

  const getCustomerDetails = (id: string) => {
    axios({
      url: "https://backend.retaino.in/api/users/getuserbyid/" + id,
      method: "get",
    })
      .then((res: any) => {
        setUser(res.data.user);

        // getDetails(type, ID);
      })
      .catch((err: any) => {});
  };

  const handlePrintInvoice = useReactToPrint({
    content: () => targetRef.current,
  });
  const handlePrintThermal = useReactToPrint({
    content: () => thermalInvoiceRef.current,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      let search = new URLSearchParams(location.search);
      search.set("search", searchTerm);
      navigate(`/orders?${String(search)}`);
    }, 500); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleOrderUpdate = (data: any) => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}orders/product/` + inView?._id,
      method: "patch",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        if (res.data.message === "updated successfully") {
          toast.success("Order status updated successfully.");
          getData();
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong.");
        setLoading(false);
      });
  };

  const calculateAmt = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ) => {
    // this will return the actual price of item after removing the tax.
    if (isInclusive) {
      return (subTotal * (100 / (100 + gst))).toFixed(2);
    }
    // this will return the price after adding tax .

    return (subTotal + (gst / 100) * subTotal).toFixed(2);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}

      <Content>
        <ContentHead
          title={"Orders"}
          showBtn1={true}
          btn1Name={updateMode ? "update_btn1" : "btn1"}
          btn1Text={"Add New"}
          isIcon1={true}
          iconType1={faPlus}
          btn1type="filled"
          isMoreSettings={true}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search name, phone, email ..."
                value={searchTerm}
                onChange={handleChange}
              />
            </div>
          }
          moreSettingsList={[
            // { icon: "import", title: "Import", name: "import" },
            { icon: "export", title: "Export", name: "export" },
            // { icon: "logs", title: "Logs", name: "logs" },/
          ]}
          handleMoreClick={(name: any) => {
            if (name === "import") {
              navigate("/contacts/import-file");
            } else if (name === "export") {
              // exportExcel();
              buttonRef.current.link.click();
            } else if (name === "logs") {
              navigate("/contacts/logs");
            }
          }}
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              navigate("/orders/add");
            }
          }}
        />
        <ContentBody style={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              // position: "relative",
            }}
          >
            {secondaryView && (
              <div className={styles.filter_container}>
                <div className={styles.sdv_buttons}>
                  <div className={styles.dropdown}>
                    <div
                      className={styles.dropdownHeader}
                      onClick={toggleDropdown}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faReceipt} />
                      Print Invoice
                      <span className={styles.arrow}>{isOpen ? "▲" : "▼"}</span>
                    </div>
                    {isOpen && (
                      <ul className={styles.dropdownList}>
                        <li
                          className={styles.dropdownListItem}
                          onClick={() => handleOptionClick("pdf")}
                        >
                          <FontAwesomeIcon icon={faFilePdf} />
                          PDF
                        </li>
                        <li
                          className={styles.dropdownListItem}
                          onClick={() => handleOptionClick("print")}
                        >
                          <FontAwesomeIcon icon={faReceipt} />
                          Print
                        </li>
                      </ul>
                    )}
                  </div>
                  {inView?.logisticsDetails?.data?.docket_print ? (
                    <a
                      href={inView?.logisticsDetails?.data?.docket_print}
                      download
                      style={{
                        background: "none",
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faTags} />
                      Generate Label
                    </a>
                  ) : (
                    <button onClick={handleGenetrateLabel}>
                      {" "}
                      <FontAwesomeIcon icon={faTags} />
                      Generate Label
                    </button>
                  )}
                </div>
              </div>
            )}
            {secondaryView ? (
              <div className={styles.secondary_view}>
                <div className={styles.secondary_list_view}>
                  {data.map((val: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={styles.secondary_short_item}
                        style={
                          val._id === inView?._id
                            ? {
                                background: "var(--brand-color)",
                                color: "white",
                              }
                            : {}
                        }
                        onClick={() => {
                          setInView(val);
                          setTempPaymentStatus(val?.paymentStatus);
                        }}
                      >
                        <div>
                          <p>{val?.orderRefId || "--"}</p>
                          <span>{val?.shippingAddress?.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.secondary_detail_view}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                    }}
                  >
                    <div className={styles.sdv_head}>
                      <p style={{ textTransform: "capitalize" }}>
                        {inView?.orderRefId}
                      </p>
                    </div>
                    <CloseIcon
                      className={styles.sdv_close_btn}
                      onClick={() => {
                        setSecondaryView(false);
                        setInView({});
                      }}
                    />
                  </div>
                  {true && (
                    <>
                      <div className={styles.secondary_content}>
                        <div className={styles.secondary_content_inner}>
                          <div>
                            <span>Order Id</span>
                            <p>{inView?.orderRefId || "--"}</p>
                          </div>

                          <div>
                            <span>Name</span>
                            <p>{inView?.shippingAddress?.name || "--"}</p>
                          </div>
                          <div>
                            <span>Order Status</span>
                            {!editOrderStatus ? (
                              <p>
                                {inView?.fulfilmentStatus
                                  ? fulfilmentObj[inView?.fulfilmentStatus]
                                  : "--"}{" "}
                                <EditIcon
                                  onClick={() => {
                                    setTempOrderStatus(
                                      inView?.fulfilmentStatus
                                    );
                                    setEditOrderStatus(true);
                                  }}
                                />
                              </p>
                            ) : (
                              <div className={styles.inline_select_view}>
                                <select
                                  value={tempOrderStatus}
                                  onChange={(e: any) =>
                                    setTempOrderStatus(e.target.value)
                                  }
                                >
                                  <option value="received">Received</option>
                                  <option value="packaging">Packaging</option>
                                  <option value="shipped">Shipped</option>
                                  <option value="outfordelivery">
                                    Out For Delivery
                                  </option>
                                  <option value="completed">Completed</option>
                                  <option value="cancelled">Cancelled</option>
                                </select>
                                <FontAwesomeIcon
                                  icon={faClose}
                                  color={"red"}
                                  onClick={() => setEditOrderStatus(false)}
                                />
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color={"green"}
                                  onClick={() =>
                                    handleOrderUpdate({
                                      fulfilmentStatus: tempOrderStatus,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div>
                            <span>Mode Of Payment</span>
                            <p>{inView?.modeOfPayment || "--"}</p>
                          </div>
                          <div>
                            <span>Payment Status</span>
                            {!editPaymentStatus ? (
                              <p>
                                {inView?.paymentStatus || "--"}{" "}
                                <EditIcon
                                  onClick={() => {
                                    setTempPaymentStatus(inView?.paymentStatus);
                                    setEditPaymentStatus(true);
                                  }}
                                />
                              </p>
                            ) : (
                              <div className={styles.inline_select_view}>
                                <select
                                  value={tempPaymentStatus}
                                  onChange={(e: any) =>
                                    setTempPaymentStatus(e.target.value)
                                  }
                                >
                                  <option value="paid">Paid</option>
                                  <option value="unpaid">Unpaid</option>
                                  <option value="partialPaid">
                                    Partial Paid
                                  </option>
                                  <option value="partialUnpaid">
                                    Partial Unpaid
                                  </option>
                                  <option value="pending">Pending</option>
                                  <option value="failed">Failed</option>
                                </select>
                                <FontAwesomeIcon
                                  icon={faClose}
                                  color={"red"}
                                  onClick={() => setEditPaymentStatus(false)}
                                />
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color={"green"}
                                  onClick={() =>
                                    handleOrderUpdate({
                                      paymentStatus: tempPaymentStatus,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </div>
                          {inView?.refundStatus && (
                            <div>
                              <span>Refund Status</span>
                              <p>{inView?.refundStatus || "--"}</p>
                            </div>
                          )}
                          {inView?.returnStatus && (
                            <div>
                              <span>Return Status</span>
                              <p>{inView?.returnStatus}</p>
                            </div>
                          )}
                          <div>
                            <span>Sub Total</span>
                            <p>{inView?.subTotal}</p>
                          </div>
                          {inView?.discount.length !== 0 && (
                            <div>
                              <span>Discount</span>
                              <p>-{inView?.discount[0]?.totalDiscount}</p>
                            </div>
                          )}
                          <div>
                            <span>Total</span>
                            <p>{inView?.total}</p>
                          </div>
                        </div>
                      </div>
                      {inView?.logisticsDetails?.status && (
                        <div className={styles.secondary_content}>
                          <div>
                            <h4 style={{ fontWeight: 500 }}>
                              Shipping Details
                            </h4>
                          </div>
                          <div className={styles.secondary_content_inner}>
                            <div>
                              <span>Docket Number.</span>
                              <p>
                                {inView?.logisticsDetails?.data?.docket_number}
                              </p>
                            </div>
                            <div>
                              <span>BRN</span>
                              <p>{inView?.logisticsDetails?.data?.brn}</p>
                            </div>
                            <div>
                              <span>Estimated Delivery </span>
                              <p>
                                {
                                  inView?.logisticsDetails?.data
                                    ?.estiimated_delivery
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className={styles.secondary_content}>
                        <div>
                          <h4 style={{ fontWeight: 500 }}>About Product</h4>
                        </div>
                        {inView.products?.map((product: any, ind: any) => {
                          return (
                            <div className={styles.secondary_content_inner}>
                              <div>
                                <span>Title</span>
                                <p>{product?.productId?.title || "--"}</p>
                              </div>
                              <div>
                                <span>Category</span>
                                <p>{product?.productId?.category || "--"}</p>
                              </div>
                              <div>
                                <span>Collection</span>
                                <p>
                                  {product?.productId?.collectionName || "--"}
                                </p>
                              </div>
                              <div>
                                <span>Price</span>
                                <p>
                                  {!product.productId?.pricing.inclusiveOfGST
                                    ? product.productId?.pricing.price
                                    : Number(
                                        calculateAmt(
                                          product.productId?.pricing.price,
                                          product.productId?.pricing
                                            .inclusiveOfGST,
                                          product.productId?.pricing.igst
                                        )
                                      ) || "--"}
                                </p>
                              </div>
                              <div>
                                <span>Quantity</span>
                                <p>{product?.quantity || "--"}</p>
                              </div>
                              <div>
                                <span>IGST(%)</span>
                                <p>
                                  {product?.productId?.pricing.igst || "--"}
                                </p>
                              </div>
                              <div>
                                <span>Total Price</span>
                                <p>
                                  {" "}
                                  {product.productId?.pricing.inclusiveOfGST
                                    ? product.quantity *
                                      product.productId?.pricing.price
                                    : product.quantity *
                                      Number(
                                        calculateAmt(
                                          product.productId?.pricing.price,
                                          product.productId?.pricing
                                            .inclusiveOfGST,
                                          product.productId?.pricing.igst
                                        )
                                      )}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className={styles.secondary_content}>
                        <h4>Cutomer Details</h4>
                        <div className={styles.secondary_content_inner}>
                          <div>
                            <span>Name</span>
                            <p>{inView?.shippingAddress?.name || "--"}</p>
                          </div>
                          <div>
                            <span>Email</span>
                            <p>{user?.email || "--"}</p>
                          </div>
                          <div>
                            <span>Mobile</span>
                            <p>{user?.mobile?.number || "--"}</p>
                          </div>

                          <div>
                            <span>Shipping Address</span>

                            <p>
                              {/* @ts-ignore */}
                              {inView?.shippingAddress?.name},{" "}
                              {
                                // @ts-ignore
                                inView?.shippingAddress?.addLineOne
                              }{" "}
                              {
                                // @ts-ignore
                                inView?.shippingAddress?.addLineTwo
                              }{" "}
                              {
                                //@ts-ignore
                                inView?.shippingAddress?.landmark
                              }{" "}
                              {/* @ts-ignore */}
                              {inView?.shippingAddress?.city}{" "}
                              {
                                // @ts-ignore
                                inView?.shippingAddress?.state
                              }{" "}
                              - {/* @ts-ignore */}
                              {inView?.shippingAddress?.pincode}
                            </p>
                          </div>
                          <div>
                            <span>Billing Address</span>

                            <p>
                              {/* @ts-ignore */}
                              {inView?.billingAddress?.name},{" "}
                              {
                                // @ts-ignore
                                inView?.billingAddress?.addLineOne
                              }{" "}
                              {
                                // @ts-ignore
                                inView?.billingAddress?.addLineTwo
                              }{" "}
                              {
                                //@ts-ignore
                                inView?.billingAddress?.landmark
                              }{" "}
                              {/* @ts-ignore */}
                              {inView?.billingAddress?.city}{" "}
                              {
                                // @ts-ignore
                                inView?.billingAddress?.state
                              }{" "}
                              - {/* @ts-ignore */}
                              {inView?.billingAddress?.pincode}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className={styles.table_container}>
                  <table className={styles.responsive_table}>
                    {/* <thead>
                      <tr>
                        <th> Order Id</th>
                        <th>Customer Name</th>
                        <th>Order Value</th>
                        <th>Order Date</th>
                        <th>Mode of Payment</th>
                        <th>Payment Status</th>
                        <th>Order Status</th>
                      </tr>
                    </thead> */}
                    <TableHead
                      head={[
                        { title: "Order ID" },
                        { title: "Customer Name" },
                        { title: "Order Amount" },
                        { title: "Order Date" },
                        {
                          title: "Mode Of Payment",
                          value: "modeOfPayment",
                          filter: true,
                        },
                        {
                          title: "Payment Status",
                          value: "paymentStatus",
                          filter: true,
                        },
                        {
                          title: "Order Status",
                          value: "orderStatus",
                          filter: true,
                        },
                        {
                          title: "",
                          value: "",
                          filter: false,
                        },
                      ]}
                      filtervalue={{
                        modeOfPayment: [
                          "cod",
                          "upi1",
                          "upi2",
                          "credit",
                          "debit",
                          "netbanking",
                          "emi",
                          "wallet",
                          "linkPayment",
                          "pg",
                        ],
                        paymentStatus: [
                          "paid",
                          "unpaid",
                          "partialPaid",
                          "partialUnpaid",
                          "pending",
                          "failed",
                        ],
                        orderStatus: [
                          "received",
                          "packaging",
                          "shipped",
                          "outfordelivery",
                          "completed",
                          "cancelled",
                        ],
                      }}
                      onHeadClick={props?.onHeadClick}
                      showIconAt={props?.showIconAt}
                      icon={props?.icon}
                    />
                    <tbody>
                      {data?.map((val: any, index: any) => {
                        return (
                          <SingleRow
                            data={val}
                            key={index}
                            changeSelect={() => {}}
                            disableForAction={disableForAction}
                            getData={getData}
                            onRowClick={() => {
                              setInView(val);
                              setTempPaymentStatus(val?.paymentStatus);
                              setSecondaryView(true);
                            }}
                            handleGenetrateLabel={() => {
                              setInView(val);
                              handleGenetrateLabel();
                            }}
                            handleOptionClick={() => {
                              setInView(val);
                              handleOptionClick("print");
                            }}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {data?.length >= 15 && (
                  <div
                    style={{
                      // border: "1px solid ",
                      paddingBlock: "5px",
                      background: "var(--blue-light)",
                      borderRadius: "0px 0px 10px 10px",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Pagination
                      pageNo={
                        Number(
                          new URLSearchParams(location.search).get("page")
                        ) || 1
                      }
                      changePage={(val: number) => {
                        let search = new URLSearchParams(location.search);

                        search.set("page", String(val));

                        navigate(`/orders?${String(search)}`);
                      }}
                      total={total}
                      showDrop={false}
                      perPage={
                        Number(
                          new URLSearchParams(location.search).get("limit")
                        ) || 15
                      }
                      changeDrop={(val: number) => {
                        let search = new URLSearchParams(location.search);

                        search.set("limit", String(val));

                        navigate(`/orders?${String(search)}`);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </ContentBody>
      </Content>

      {cancelModal && (
        <div className={styles.cancel_modal_container}>
          <div className={styles.cancel_card}>
            <h1>Cancel Order</h1>
            <p>
              Are you sure about this action ? user will see their status as :{" "}
            </p>
            {/* @ts-ignore */}
            {(this.state.data.modeOfPayment === "upi1" ||
              // @ts-ignore
              this.state.data.modeOfPayment === "upi2" ||
              // @ts-ignore
              this.state.data.modeOfPayment === "credit" ||
              // @ts-ignore
              this.state.data.modeOfPayment === "debit" ||
              // @ts-ignore
              this.state.data.modeOfPayment === "netbanking" ||
              // @ts-ignore
              this.state.data.modeOfPayment === "emi" ||
              // @ts-ignore
              this.state.data.modeOfPayment === "wallet") && (
              <div className={styles.cancel_fields_container}>
                <InputField
                  type="select"
                  isLabel={true}
                  lable={"Refund Status"}
                  value={cancelRefundStatus}
                  onChange={(e: any) => {
                    setRefundStatus(e.target.value);
                  }}
                  options={[
                    { title: "Request", value: "requested" },
                    { title: "Processing", value: "processing" },
                    { title: "Completed", value: "completed" },
                  ]}
                  accessText="title"
                  accessValue="value"
                />
              </div>
            )}
            <div className={styles.cancel_btns}>
              <button onClick={() => setCancelModal(false)}>Discard</button>
              <button
              // onClick={() =>
              //   updateOrderStatus({ fulfilmentStatus: "cancelled" })
              // }
              >
                Cancel Order
              </button>
            </div>
          </div>
        </div>
      )}

      {!printOptionModal &&
        localStorage.getItem("printingOption") !== "thermal" && (
          <div
            style={{
              background: "white",
              position: "absolute",
              top: "-100000px",
              right: "-100000px",
            }}
          >
            <Invoice
              ref={targetRef}
              user={user}
              // product={product}
              order={inView}
              admin={admin}
            />
          </div>
        )}
      {!printOptionModal && localStorage.getItem("printingOption") !== "a4" && (
        <div
          style={{
            position: "absolute",
            top: "-10000px",
            left: "-10000px",
          }}
        >
          <ThermalInvoice
            user={user}
            // product={product}
            order={inView}
            ref={thermalInvoiceRef}
            admin={admin}
          />
        </div>
      )}
      {printOptionModal && (
        <div>
          <PrintOptions
            user={user}
            // product={product}
            order={inView}
            admin={admin}
            // ref={targetRef}
            handlePrintInvoice={handlePrintInvoice}
            close={() => setPrintOptionModal(false)}
          />
        </div>
      )}
      {labelGeneratorModal && (
        <ShippingLabel
          user={user}
          product={product}
          order={inView}
          close={() => setLabelGeneratorModal(false)}
        />
      )}
      <div
        // ref={buttonRef}
        style={{ position: "absolute", top: "-10000px", left: "-1000px" }}
      >
        <CSVLink data={csvData || [{}]} ref={buttonRef}>
          <FontAwesomeIcon icon={faFileExport} color="white" /> Export to csv
        </CSVLink>
      </div>
    </>
  );
};

const fulfilmentObj = {
  received: "Received",
  packaging: "Packaging",
  shipped: "Shipped",
  outfordelivery: "Out For Delivery",
  completed: "Completed",
  cancelled: "Cancelled",
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.sales_access.write) return true;

  return false;
};

export default withRouter(ProductOrderDetails);
