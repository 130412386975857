import styles from "../../Styles/inputField.module.css";
import { ReactComponent as Down } from "../../Assets/icons/down.svg";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

type Props = {
  isLabel: boolean;
  value: string;
  lable?: any;
  type: string;
  isRequired?: boolean;
  placeholder?: string;
  style?: any;
  name?: string;
  onChange: any;
  options?: any;
  accessText?: string | undefined;
  accessValue?: string | undefined;
  onKeyPress?: any;
  error?: boolean;
  errorText?: string;
  sideIcon?: boolean;
  fieldStyle?: any;
  disabled?: boolean;
};

const InputField = (props: Props) => {
  const selectRef: any = useRef();

  return (
    <>
      <div
        className={styles.field_contianer + " h-fit"}
        style={props.style ? { ...props.style } : {}}
      >
        {props.isLabel && (
          <p>
            {props.lable} {props.isRequired && "*"}
          </p>
        )}
        {props.type === "select" ? (
          <div
            className={`${styles.select_container} ${
              props.error ? styles.error : ""
            }`}
          >
            <select
              ref={selectRef}
              value={props.value}
              onChange={props.onChange}
              name={props?.name ? props.name : ""}
            >
              <option value="" disabled selected>
                {props?.placeholder ? props?.placeholder : "Select your option"}
              </option>
              {props?.options?.map((val: any, index: any) => {
                return (
                  <option
                    key={index}
                    value={val[props?.accessValue!]}
                    disabled={val?.disabled ? val.disabled : false}
                  >
                    {val[props?.accessText!]}
                  </option>
                );
              })}
            </select>
            <Down
              onClick={() => {
                if (selectRef.current) {
                  selectRef.current?.click();
                }
              }}
            />
          </div>
        ) : props.type === "textarea" ? (
          <textarea
            className={styles.textarea}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
          ></textarea>
        ) : props.type === "date" ? (
          <div className={styles.input_container}>
            <input
              className={`${props?.error ? styles.error : ""} ${
                props?.fieldStyle ? props.fieldStyle : ""
              }`}
              type={props.type}
              placeholder={props.placeholder}
              name={props?.name ? props.name : ""}
              value={props.value}
              onChange={props.onChange}
              onKeyPress={props?.onKeyPress}
            ></input>
          </div>
        ) : props.type === "address" ? (
          <div className={styles.input_container_add}>
            <input
              className={`${props?.error ? styles.error : ""} ${
                props?.fieldStyle ? props.fieldStyle : ""
              }`}
              type={"text"}
              placeholder={"Address Line One"}
              name={"addLineOne"}
              value={props.value}
              onChange={props.onChange}
              onKeyPress={props?.onKeyPress}
              disabled={props.disabled || false}
            ></input>
            <input
              className={`${props?.error ? styles.error : ""} ${
                props?.fieldStyle ? props.fieldStyle : ""
              }`}
              type={"text"}
              placeholder={"Address 2"}
              name={"addLineTwo"}
              // value={props.value}
              onChange={props.onChange}
              onKeyPress={props?.onKeyPress}
            ></input>
            <span>
              <input
                className={`${props?.error ? styles.error : ""} ${
                  props?.fieldStyle ? props.fieldStyle : ""
                }`}
                type={"text"}
                placeholder={"City"}
                name={"city"}
                // value={props.value}
                onChange={props.onChange}
                onKeyPress={props?.onKeyPress}
              ></input>
              <input
                className={`${props?.error ? styles.error : ""} ${
                  props?.fieldStyle ? props.fieldStyle : ""
                }`}
                type={"text"}
                placeholder={"State"}
                name={"state"}
                // value={props.value}
                onChange={props.onChange}
                onKeyPress={props?.onKeyPress}
              ></input>
              <input
                className={`${props?.error ? styles.error : ""} ${
                  props?.fieldStyle ? props.fieldStyle : ""
                }`}
                type={"text"}
                placeholder={"Country"}
                name={"country"}
                // value={props.value}
                onChange={props.onChange}
                onKeyPress={props?.onKeyPress}
              ></input>
              <input
                className={`${props?.error ? styles.error : ""} ${
                  props?.fieldStyle ? props.fieldStyle : ""
                }`}
                type={"text"}
                placeholder={"Pin Code"}
                name={`pincode`}
                // value={props.value}
                onChange={props.onChange}
                onKeyPress={props?.onKeyPress}
              ></input>
            </span>
          </div>
        ) : (
          <div className={styles.input_container}>
            <input
              className={`${props?.error ? styles.error : ""} ${
                props?.fieldStyle ? props.fieldStyle : ""
              }`}
              type={props.type}
              placeholder={props.placeholder}
              name={props?.name ? props.name : ""}
              value={props.value}
              onChange={props.onChange}
              onKeyPress={props?.onKeyPress}
              disabled={props.disabled}
            ></input>
            {props?.sideIcon && <div className={styles.side_icon}>%</div>}
          </div>
        )}
        {/* error text */}
        {props?.error && (
          <div className={styles.error_text}>
            <FontAwesomeIcon icon={faCircleExclamation} color="#C4270D" />
            <p>Error text goes here.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default InputField;
