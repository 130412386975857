import {
  faCheckCircle,
  faClose,
  faPlay,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import styles from "../../Styles/imageFolder.module.css";
import axios, { all } from "axios";
import LazyLoad from "react-lazyload";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Grid, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import { uploadBulkImages } from "../../Apis/callApi";
import toast from "react-hot-toast";
import { checkForImg } from "../../Utils/UploadImage";
import Pagination from "../Table/Pagination";

type Props = {
  open?: Boolean;
  onClose?: () => void;
  onSave: (val: string[]) => void;
  selectMultiple?: Boolean; // this defines how many images can be selected
  alreadySelected: any;
  text?: string;
  media?: any;
  updateMedia?: any;
  numberOfImagesPerRow?: number;
};

const ImageFolder = (props: Props) => {
  const [startClose, setStartClose] = useState(false);
  const [section, setSection] = useState("asset");
  const [selected, setSelected]: any = useState([]);
  const [media, setMedia]: any = useState([]);
  const [open, setOpen] = useState(false);
  const [newImages, setNewImages]: any = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [show, setShow] = useState("all");
  const [allMedia, setAllMedia] = useState([]);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const isFirstRender = useRef(true); // Ref to track the first render

  useEffect(() => {
    if (open) {
      setSelected(props.media);
      getAllMedia();
    }
  }, [open]);

  const getAllMedia = () => {
    axios({
      url: `${
        process.env.REACT_APP_BASE_URL
      }uploadbulkimages/images?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res: any) => {
        setMedia(res.data.images);
        setAllMedia(res.data.images);
      })
      .catch((err: any) => {});
  };

  const getCurrentMedia = () => {
    let temp = [...allMedia];

    if (search) {
      const sanitizedSearch = search.toLowerCase().replace(/[^a-z0-9]/g, ""); // Lowercase and remove special characters
      temp = temp.filter((v) =>
        v.url
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "")
          .includes(sanitizedSearch)
      );
    }
    if (show === "all") {
      return temp;
    } else if (show === "images") {
      return temp.filter((v) => checkForImg(v.url));
    } else if (show === "videos") {
      return temp.filter((v) => !checkForImg(v.url));
    }
  };

  useEffect(() => {
    let cm = getCurrentMedia();
    setMedia(cm);
  }, [show, search]);

  useEffect(() => {
    setSelected([...props.alreadySelected]);
  }, [props.alreadySelected]);

  const closeModal = () => {
    setStartClose(true);
    setTimeout(() => {
      setOpen(false);
      setSelected([]);
      setStartClose(false);
    }, 300);
  };

  const handleSelected = (id: string) => {
    // check if id already in selected or not

    let temp: string[] = [...selected];

    if (selected.find((val: string) => val === id)) {
      let index = selected.findIndex((val: string) => val === id);
      temp.splice(index, 1);
    } else {
      if (props.selectMultiple) {
        temp.push(id);
      } else {
        temp = [id];
      }
    }
    setSelected(temp);
  };

  const checkSelected = (id: string) => {
    return selected.find((val: string) => val === id);
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: any) => {
    const index = rowIndex * 4 + columnIndex; // Calculate the media index based on row and column
    const item = media[index];

    if (item === undefined || item === null) return null;

    if (show === "images" && !checkForImg(item.url)) return null;

    if (show === "videos" && checkForImg(item.url)) return null;

    if (!item) return null; // Handle empty cells if media array is not perfectly divisible

    return (
      <div
        key={key}
        style={{
          ...style,
          padding: "10px",
        }}
        onClick={() => handleSelected(item.url)}
      >
        <div
          className={styles.asset}
          style={{
            border: "1px solid lightgrey",
            borderRadius: "5px",
            position: "relative",
            overflow: "hidden",
            height: "100%",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            background: "#f0f0f0",
          }}
        >
          <AssetImage src={item} alt={`Image ${index}`} />
          {checkSelected(item.url) && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="asset_selected"
              color="green"
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                height: "20px",
                width: "20px",
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const handleSave = () => {
    if (section === "asset") {
      props.updateMedia(selected);
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const handleNewImages = (e: any) => {
    const files = Array.from(e.target.files).map((v: any) => ({
      url: URL.createObjectURL(v),
      obj: v,
    }));
    setNewImages((prev: any) => [...prev, ...files]);
  };

  const handleUploadAndInsert = () => {
    setUploadLoading(true);
    uploadBulkImages(newImages.map((v: any) => v.obj))
      .then((res: any) => {
        setUploadLoading(false);
        props.updateMedia([...props.media, ...res.data.images]);
        setOpen(false);
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Something went wrong.");
        setUploadLoading(false);
      });
  };

  return (
    <>
      {uploadLoading && (
        <div className="loading">
          <span className="loader"></span>
        </div>
      )}
      {props.media.length === 0 ? (
        <div
          style={{
            width: "100%",
            border: "1px dashed var(--brand-color)",
            height: "200px",
            borderRadius: "15px",
            background: "var(--blue-light)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "var(--blue-dark)",
            cursor: "pointer",
            gap: "10px",
            marginTop: "10px",
          }}
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          <p>{props?.text}</p>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "15px",
            width: "100%",
            boxSizing: "border-box",
            marginTop: "10px",
          }}
        >
          <MediaGallery
            media={props?.media}
            updateMedia={props?.updateMedia}
            setOpen={setOpen}
          />
          {props?.selectMultiple && (
            <div
              style={{
                width: "160px",
                border: "1px dashed var(--brand-color)",
                aspectRatio: "1/1",
                borderRadius: "15px",
                background: "var(--blue-light)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "var(--blue-dark)",
                cursor: "pointer",
                gap: "10px",
                textAlign: "center",
              }}
              onClick={() => setOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
              <p style={{ fontSize: ".8rem" }}>{props?.text}</p>
            </div>
          )}
        </div>
      )}
      {open && (
        <div className={styles.container} onClick={closeModal}>
          <div
            className={`${styles.main} ${startClose && styles.main_close}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.head}>
              <CloseIcon onClick={closeModal} />
              <p>Upload Media</p>
            </div>

            <div className={styles.sections}>
              <div
                className={`${styles.section} ${
                  section === "asset" && styles.section_selected
                }`}
                onClick={() => setSection("asset")}
              >
                Asset Library
              </div>
              <div
                className={`${styles.section} ${
                  section === "upload" && styles.section_selected
                }`}
                onClick={() => setSection("upload")}
              >
                Upload
              </div>
            </div>

            {section === "asset" && (
              <div className={styles.assets_main}>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <ul
                    style={{
                      display: "flex",
                      listStyle: "none",
                      border: "1px solid var(--blue-dark)",
                      width: "fit-content",
                      padding: "2px",
                      borderRadius: "5px",
                      gap: "5px",
                      fontSize: ".8rem",
                    }}
                  >
                    <li
                      style={
                        show === "all"
                          ? {
                              background: "var(--brand-color)",
                              color: "white",
                              padding: "5px 15px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }
                          : {
                              padding: "5px 15px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }
                      }
                      onClick={() => setShow("all")}
                    >
                      All
                    </li>
                    <li
                      style={
                        show === "images"
                          ? {
                              background: "var(--brand-color)",
                              color: "white",
                              padding: "5px 15px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }
                          : {
                              padding: "5px 15px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }
                      }
                      onClick={() => setShow("images")}
                    >
                      Images
                    </li>
                    <li
                      style={
                        show === "videos"
                          ? {
                              background: "var(--brand-color)",
                              color: "white",
                              padding: "5px 15px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }
                          : {
                              padding: "5px 15px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }
                      }
                      onClick={() => setShow("videos")}
                    >
                      Videos
                    </li>
                  </ul>
                  <div
                    style={{
                      width: "300px",
                      height: "35px",
                      border: "1px solid var(--blue-dark)",
                      background: "var(--blue-light)",
                      borderRadius: "5px",
                      alignItems: "center",
                      display: "flex",
                      paddingInline: "5px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        color: "var(--blue-dark)",
                        height: "50%",
                        aspectRatio: "1/1",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        flexShrink: 0,
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        height: "100%",
                        flex: 1,
                        border: "none",
                        outline: "none",
                        borderRadius: "5px",
                        background: "transparent",
                        fontSize: "1rem",
                        padding: "5px",
                      }}
                      placeholder="Search Media"
                      value={search}
                      onChange={(e: any) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <AutoSizer>
                  {({ width, height }: any) => (
                    <Grid
                      columnCount={4} // Number of columns
                      rowCount={Math.ceil(media.length / 4)} // Total rows based on media length
                      columnWidth={Math.floor(width / 4)} // Calculate column width dynamically
                      rowHeight={210} // Match asset height + padding
                      width={width} // Auto-sized width
                      height={height} // Auto-sized height
                      cellRenderer={cellRenderer} // Render each cell
                      overscanRowCount={5} // Preload additional rows for smooth scrolling
                    />
                  )}
                </AutoSizer>
              </div>
            )}
            {section === "upload" && (
              <div className={styles.assets_main}>
                <div className={styles.upload_container}>
                  <p>Upload images that meet the requirements.</p>
                  <div
                    className={styles.upload_section}
                    style={newImages.length !== 0 ? { height: "300px" } : {}}
                  >
                    <input
                      type="file"
                      accept="image/*,video/*"
                      multiple
                      onChange={handleNewImages}
                    />
                    <p>To Upload your media, drag files here.</p>
                    <span>or</span>
                    <div>Upload from Computer</div>
                  </div>
                  <p>Assets Upload will be saved in asset library.</p>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(5,1fr)",
                      width: "100%",
                      height: "fit",
                      gap: "15px",
                      marginTop: "20px",
                    }}
                  >
                    {newImages.map((newImage: any, index: any) => {
                      return (
                        <div
                          style={{
                            width: "100%",
                            aspectRatio: "1/1",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            border: "1px solid lightgrey",
                            background: "#f1f1f1",
                            position: "relative",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faClose}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              background: "var(--blue-light)",
                              padding: "5px",
                              color: "var(--blue-dark)",
                              borderRadius: "100%",
                              transform: "translate(30%,-30%)",
                              zIndex: 10,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let temp = [...newImages];
                              temp.splice(index, 1);
                              setNewImages(temp);
                            }}
                          />
                          {newImage.obj?.type.includes("video") && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "rgba(0,0,0,.1)",
                                zIndex: 5,
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPlay}
                                style={{
                                  color: "var(--blue-light)",
                                  fontSize: "2rem",
                                }}
                              />
                            </div>
                          )}
                          {newImage.obj?.type.includes("video") ? (
                            <video
                              src={newImage.url}
                              autoPlay={false}
                              muted={true}
                              playsInline
                              style={{
                                width: "100%",
                                height: "auto",
                                aspectRatio: "1/1",
                                objectFit: "contain",
                                objectPosition: "center center",
                              }}
                            ></video>
                          ) : (
                            <img
                              src={newImage.url}
                              alt=""
                              style={{
                                width: "100%",
                                height: "auto",
                                aspectRatio: "1/1",
                                objectFit: "contain",
                                objectPosition: "center center",
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {section === "asset" ? (
              <div className={styles.footer}>
                <button onClick={handleSave}>Save</button>
                <button onClick={() => setOpen(false)}>Cancel</button>
                {/* <div style={{ height: "40px", marginLeft: "auto" }}>
                  <Pagination
                    pageNo={pageNo}
                    changePage={(v: any) => setPageNo(v)}
                    total={media.length}
                    perPage={30}
                  />
                </div> */}
              </div>
            ) : (
              <div className={styles.footer}>
                <button onClick={handleUploadAndInsert}>
                  Upload and Insert in Media
                </button>
                <button
                  onClick={() => setOpen(false)}
                  style={{
                    background: "var(--blue-light)",
                    borderRadius: "5px",
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const AssetImage = ({ src, alt }: any) => {
  return (
    <>
      {checkForImg(src.url) ? (
        <img
          src={src.url}
          alt={alt}
          loading="lazy" // Native lazy loading
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain", // Maintain original aspect ratio
            objectPosition: "center center", // Center the image within the container
            borderRadius: "9px",
          }}
        />
      ) : (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0,0,0,.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faPlay}
              style={{ color: "var(--blue-light)", fontSize: "2rem" }}
            />
          </div>
          <video
            src={src.url}
            autoPlay={false}
            muted={true}
            playsInline
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain", // Maintain original aspect ratio
              objectPosition: "center center", // Center the image within the container
              borderRadius: "9px",
            }}
          ></video>
        </>
      )}
    </>
  );
};

const MediaGallery = (props: any) => {
  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // If dropped outside the list or no movement
    if (!destination || source.index === destination.index) {
      return;
    }

    // Reordering the array
    const tempMedia = Array.from(props.media);
    const [removed] = tempMedia.splice(source.index, 1);
    tempMedia.splice(destination.index, 0, removed);

    props.updateMedia(tempMedia); // Update the state in parent
  };

  function customStyleWhenDragging(isDragging: boolean) {
    return isDragging
      ? {
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "grabbing",
        }
      : {};
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="mediaGallery" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
              width: "100%",
              boxSizing: "border-box",
              marginTop: "10px",
            }}
          >
            {props.media.map((mdi: any, index: any) => (
              <Draggable
                key={index}
                draggableId={`media-${index}`}
                index={index}
              >
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style, // Required for drag position
                      // position: "relative",
                      margin: "0", // Avoid margin/padding conflicts
                      zIndex: snapshot.isDragging ? 10 : "auto", // Elevate while dragging
                      ...customStyleWhenDragging(snapshot.isDragging), // Optional: Add visual feedback
                      width: "160px",
                      height: "160px",
                      border: "1px solid lightgray",
                      background: "#f1f1f1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      objectFit: "cover",
                      cursor: "move",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      {!checkForImg(mdi) && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            background: "rgba(0,0,0,.1)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 2,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlay}
                            style={{
                              color: "var(--blue-light)",
                              fontSize: "2rem",
                            }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                        }}
                      >
                        {!checkForImg(mdi) ? (
                          <video
                            src={mdi}
                            autoPlay={false}
                            muted={true}
                            playsInline
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                              borderRadius: "9px",
                            }}
                          ></video>
                        ) : (
                          <img
                            src={mdi}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                              borderRadius: "9px",
                            }}
                            alt=""
                          />
                        )}
                      </div>
                      <FontAwesomeIcon
                        color="white"
                        style={{
                          position: "absolute",
                          height: "16px",
                          width: "16px",
                          color: "var(--blue-dark)",
                          background: "var(--blue-light)",
                          padding: "5px",
                          cursor: "pointer",
                          borderRadius: "100%",
                          right: 0,
                          top: 0,
                          transform: "translateX(30%) translateY(-30%)",
                          zIndex: 5,
                        }}
                        icon={faClose}
                        onClick={() => {
                          let temp_mediaUrl = [...props?.media];
                          temp_mediaUrl.splice(index, 1);
                          props.updateMedia(temp_mediaUrl);
                        }}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ImageFolder;
