import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../Styles/button.module.css";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type props = {
  text: any;
  type:
    | "outlined"
    | "filled"
    | "outlined_del"
    | "light_grey"
    | "outlined_success"
    | "filled_red"
    | "filled_green"
    | "rounded_grey"
    | "none"
    | "transparent"
    | undefined;
  style?: any;
  name: string | undefined;
  clickHandler?: any;
  isIcon: any;
  iconType?: any;
};

const Button = (props: props) => {
  return (
    <button
      name={props.name}
      className={`${styles.button_container} ${
        props?.type ? styles[props.type] : ""
      }`}
      style={props?.style}
      onClick={(event: React.SyntheticEvent) => {
        props.clickHandler && props?.clickHandler(event);
      }}
    >
      {props?.isIcon && (
        <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
      )}
      {props.text}
    </button>
  );
};

export default Button;
