export function isVideo(file: any) {
  // Check if the File object is present
  if (!file || !(file instanceof File)) {
    return false;
  }

  // Get the MIME type of the file
  const fileType = file.type;

  console.log("File Type ", fileType);

  // Define an array of video MIME types
  const videoMimeTypes = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/quicktime",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/x-matroska",
    "video/3gpp",
    "video/3gpp2",
    "video/vnd.dlna.mpeg-tts",
    "video/x-flv",
    "video/x-m4v",
    "video/x-ms-asf",
    "video/x-mpeg",
    "video/x-msvideo",
    "video/h264",
    "video/h265",
    "video/avi",
    "video/vnd.rn-realvideo",
    // Add more video MIME types as needed
  ];

  // Check if the file type matches any video MIME type
  return videoMimeTypes.includes(fileType);
}
