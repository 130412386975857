import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { getAllCategoryList } from "../Apis/filterApi";
import { ReactComponent as BlogIcon } from "../Assets/newIcons/blog.svg";
import { ReactComponent as UserIcon } from "../Assets/newIcons/user.svg";
import { ReactComponent as ProductIcon } from "../Assets/newIcons/product.svg";
import { ReactComponent as OrderIcon } from "../Assets/newIcons/order.svg";
import { ReactComponent as DiscountIcon } from "../Assets/newIcons/discount.svg";
import { ReactComponent as ShoppableVideoIcon } from "../Assets/newIcons/shoppableVideo.svg";
import { ReactComponent as ComplianceIcon } from "../Assets/newIcons/compliance.svg";
import { ReactComponent as ContentIcon } from "../Assets/newIcons/content.svg";
import { ReactComponent as CaseStudyIcon } from "../Assets/newIcons/caseStudy.svg";
import { ReactComponent as LeadsIcon } from "../Assets/newIcons/leads.svg";
import { ReactComponent as JobIcon } from "../Assets/newIcons/job.svg";
import { ReactComponent as ApplicantIcon } from "../Assets/newIcons/applicants.svg";
import { ReactComponent as ServiceIcon } from "../Assets/newIcons/service.svg";
import { ReactComponent as AnalyticsIcon } from "../Assets/newIcons/analytics.svg";

type ISidebarData = { title: string; icon: any; link: string };
type IBlogTableHead = { title: string };

// fetched form api to show or hide on sidebar
export const sidebar_check = [{ blog: "" }, { contact: "" }, { career: "" }];

export const sidebar_data: ISidebarData[] = [
  { title: "Blogs", icon: "", link: "/blogs" },
];

export const blog_table_head: IBlogTableHead[] = [
  { title: "S.No" },
  { title: "Blog Title" },
  { title: "Status" },
  { title: "" },
];

export const blog_access_keys = ["sno", "title", "status", "action"];

export const jobs_table_key = [
  "title",
  "position",
  "department",
  "status",
  "action:delete,archive",
];

export const sub_category_table_head = [
  { title: "S.No.", value: "s.no" },
  { title: "Title", value: "title" },
  { title: "Action", value: "action" },
];

export const store_table_head = [
  { title: "S.No.", value: "s.no" },
  { title: "Title", value: "title" },
  { title: "Action", value: "action" },
];

export const compliance_table_head = [
  { title: "S.No." },
  { title: "Title" },
  { title: "Type" },
  { title: "Created At" },
  { title: "Last Updated" },
  { title: "Status" },
  { title: "" },
];

export const product_body_keys = [
  "inventory.sku",
  "title",
  "category",
  "collectionName",
  "inventory.currentQuantity",
  "pricing.price",
  "createdAt",
  "action:more",
];

export const order_body_keys = [
  "orderRefId",
  "customer_name",
  "date",
  "location",
  "subLocation",
  "category",
  "subCategory",
  "modeOfPayment",
  "paymentStatus",
  "fulfilmentStatus",
];

export const orders_table_head = [
  { title: "S.NO." },
  { title: "Order ID" },
  {
    title: "Booked for",
    up: faArrowUp,
    down: faArrowDown,
    default: "up",
    haveIcon: true,
  },
  { title: "Location" },
  { title: "Sub Location" },
  { title: "Category" },
  { title: "Sub Category" },
  { title: "Payment Status", filter: true },
  { title: "Order Status", filter: true },
];

export const compliance_data = [
  {
    title: "Terms",
  },
];

export const users_table_head = [
  { title: "S.No." },
  { title: "First Name" },
  { title: "Last Name" },
  { title: "Email" },
  { title: "Phone no." },
];

export const users_body_keys = [
  "firstName",
  "lastName",
  "email",
  "mobile.number",
];

export const blogg = [
  {
    path: "/analytics",
    title: "overview",
    icon: AnalyticsIcon,
    tooltip: "Analytics",
    add: false,
  },
  {
    title: "audiences",
    icon: UserIcon,
    child: [
      {
        path: "/contacts",
        title: "contacts",
        add: false,
      },
      { path: "/users", title: "user", add: false },
      { path: "/segments", title: "segments", add: false },
    ],
  },
  {
    path: "/blogs",
    title: "blog",
    index: true,
    icon: BlogIcon,
    pageType: "table",
    table_head: [
      { title: "S.No", value: "s.no" },
      { title: "Title", value: "title" },
      { title: "Created At", value: "createdata" },
      { title: "Actions", value: "actions:delete,edit" },
    ],
    body_keys: ["title", "createdAt", "actions"],
    tooltip: "Blog",
    add: false,
    addUrl: "/blog/create_blog",
  },
  {
    path: "/case-study",
    title: "caseStudy",
    icon: CaseStudyIcon,
    tooltip: "Case-Study",
    add: false,
    addUrl: "/case-study/create",
  },
  {
    path: "/jobs",
    title: "job",
    icon: JobIcon,
    pageType: "table",
    table_head: [],
    body_keys: [],
    tooltip: "Jobs",
    add: false,
    addUrl: "/jobs/create_job",
  },
  {
    path: "/applicants",
    title: "applicants",
    icon: ApplicantIcon,
    pageType: "table",
    table_head: [],
    body_keys: [],
    tooltip: "Applicants",
  },
  {
    path: "/services",
    title: "service",
    icon: ServiceIcon,
    tooltip: "Services",
    add: false,
    addUrl: "/services/add",
  },
  {
    title: "catalog",
    icon: ProductIcon,
    child: [
      // {
      //   path: "/catalog-overview",
      //   title: "Overview",
      //   icon: ProductIcon,
      // },
      {
        path: "/products",
        title: "product",
        icon: ProductIcon,
        add: true,
        addUrl: "/products/add",
      },
      {
        path: "/categories",
        title: "categories",
        icon: ProductIcon,
        add: true,
        addUrl: "/categories/add",
      },
      {
        path: "/sub-categories",
        title: "subCategories",
        icon: ProductIcon,
        add: true,
        addUrl: "/sub-categories/add",
      },
      {
        path: "/collections",
        title: "collection",
        icon: ProductIcon,
        add: true,
        addUrl: "/collections/add",
      },
      {
        path: "/bulk-imageupload",
        title: "Bulk Image Upload",
        add: false,
        addUrl: "/bulk-imageupload",
      },
    ],
  },
  {
    // path: "/orders",
    title: "sales",
    icon: OrderIcon,
    child: [
      { path: "/b2b-orders", title: "B2B Orders" },
      { path: "/orders", title: "order", add: false, addUrl: "/orders/add" },
      { path: "/carts", title: "abandonedCarts", add: false },
    ],
  },
  {
    path: "/discounts",
    title: "discount",
    icon: DiscountIcon,
    tooltip: "Discounts",
    add: false,
    addUrl: "/discounts/add",
  },
  {
    path: "/product-video",
    title: "shoppable video",
    icon: ShoppableVideoIcon,
    tooltip: "Shoppable Videos",
  },
  {
    path: "/compliance",
    title: "compliance",
    icon: ComplianceIcon,
    pageType: "table",
    tooltip: "Compliance",
    add: false,
    addUrl: "/compliance/add",
  },
  {
    path: "/content",
    title: "content",
    icon: ContentIcon,
    pageType: "table",
    tooltip: "Content",
    add: false,
    addUrl: "/content/add",
  },
];

export const services_table_head = [
  { title: "S.No." },
  { title: "Service Title" },
  { title: "Status" },
  { title: "" },
];

export const services_body_keys = ["title", "status:dropdown", "action:delete"];

export const discount_table_head = [
  { title: "S.No." },
  { title: "Discount Title" },
  { title: "Discount Code" },
  { title: "" },
];

export const discount_body_keys = [
  "discountTitle",
  "counponCode",
  "action:delete",
];

export const product_video_table_head = [
  { title: "S.No." },
  { title: "Video Title" },
  { title: "" },
];

export const product_video_body_keys = ["title", "action:delete"];

export const product_review_head = [
  "select",
  "S.No",
  "Product Name",
  "Category",
  "Sub Category",
  "Type",
];

export const category_head = ["S.No.", "Name", ""];

export const category_body_keys = ["sno", "title", "action"];

export const collection_head = [
  { title: "S.No" },
  { title: "Name" },
  { title: "" },
];

export const cart_head = [
  // { title: "_select" },
  { title: "S.No" },
  { title: "Cart Id" },
  { title: "Products" },
  { title: "Last Updated" },
];
